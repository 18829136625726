<div class="content-wrapper">
    <div class="content-body">
        <section id="articles">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold">Blog Content - {{viewContentData?.heading}}</h4>
                        <button (click)="modalService.dismissAll('')" aria-label="Close" class="close"
                                data-dismiss="modal"
                                type="button">
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row p-1">
                            <div [innerHTML]="viewContentData['content']"
                                 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Events</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button (click)="showSearch = !showSearch"
                            class="btn btn-primary pull-right color-white color-white users-btn">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button (click)="add()"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Event
                    </button>
                </div>
            </div>
            <div *ngIf="showSearch" class="row search-card mt-3">
                <div class="col-12">
                    <form [formGroup]="searchForm" class="form mt-2 mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="heading" placeholder="heading"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button (click)="search()"
                                        [disabled]="submitting"
                                        class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn">
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!--            <pre class="text-white">{{blogs|json}}</pre>-->
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Heading</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Timing</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Venue</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Short
                                                    Description</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Banner</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Cover</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody *ngIf="events && events.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let event of events; let i = index">

                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ?
                                                    pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{event?.heading}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{event?.timing}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{event?.venue}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{event?.short_description}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <div *ngIf="event && event['event_banner']" class="btn-group"
                                                     role="group">
                                                    <button (click)="bannerUpload.click()" class="btn btn-primary"
                                                            title="Upload Banner" type="button">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button (click)="openBlogBanner(event)" class="btn btn-primary"
                                                            title="View Banner" type="button">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button (click)="bannerUpload.click()"
                                                        *ngIf="event && !event['event_banner']"
                                                        class="btn btn-primary color-white"
                                                        title="Upload">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input #bannerUpload
                                                       (change)="onFileChange($event, 'EVENT_BANNER', event)"
                                                       class="form-control-file display-hidden"
                                                       type="file">
                                            </td>
                                            <td class="text-center">
                                                <div *ngIf="event && event['event_cover']" class="btn-group"
                                                     role="group">
                                                    <button (click)="coverUpload.click()" class="btn btn-primary"
                                                            title="Upload Cover" type="button">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button (click)="openBlogCover(event)" class="btn btn-primary"
                                                            title="View Cover"
                                                            type="button">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button (click)="coverUpload.click()"
                                                        *ngIf="event && !event['event_cover']"
                                                        class="btn btn-primary color-white"
                                                        title="Upload">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input #coverUpload (change)="onFileChange($event, 'EVENT_COVER', event)"
                                                       class="form-control-file display-hidden"
                                                       type="file">
                                            </td>
                                            <td class="text-center">
                                                <i (click)="edit(event)"
                                                   aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit"></i>
                                                <i (click)="blogsInfoPage(event)"
                                                   aria-hidden="true"
                                                   class="fab fa-artstation f_20 mt-1 text-primary cursor-pointer font-weight-light ml-5px"
                                                   title="Perform Actions"></i>
                                                <i (click)="updateStatus(event, 'ACTIVE')"
                                                   *ngIf="event && event['status'] === 'IN_ACTIVE'"
                                                   class="fas fa-check-circle f_20 ml-5px text-success cursor-pointer"
                                                   title="Active"></i>
                                                <i (click)="updateStatus(event, 'IN_ACTIVE')"
                                                   *ngIf="event && event['status'] === 'ACTIVE'"
                                                   class="fas fa-times-circle f_20 ml-5px text-danger cursor-pointer"
                                                   title="In Active"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Delete" (click)="delete(event.id)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="5">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="blogs && blogs.length > 0; else noDataToShowMobile" class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div *ngFor="let event of events; let i = index" class="card">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) *
                                    (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{event?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <i (click)="edit(event)"
                                       aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <!--<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Category - {{blog?.category}}
                                </div>-->
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Heading - {{event?.heading}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Content - {{event?.content}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page" [boundaryLinks]="true"
                                [collectionSize]="pagination.total" [maxSize]="5" [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="add-event" overlayClass="modal-backdrop">
        <app-add-event (valueSubmitted)="getData($event)" [data]="data">></app-add-event>
    </core-sidebar>

</div>
