import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EventComponent} from "./event.component";
import {AddEventComponent} from "./add-event/add-event.component";
import {EventInfoComponent} from "./event-info/event-info.component";

const routes: Routes = [
  {
    path: 'events',
    component: EventComponent,
    data: {animation : 'events'}
  },
  {
    path:'event/add',
    component: AddEventComponent,
    data: {animation : 'event/add'}
  },
  {
    path: 'event-info/:id',
    component: EventInfoComponent,
    data: {animation : 'event-info'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule { }
