import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {UploadService} from "./upload.service";
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class VolunteerService  extends BaseService  {

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              private fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }



  get(data): Observable<any> {
    return this.getRequest(AppUrl.VOLUNTEER(), data);
  }
}
