import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventRoutingModule } from './event-routing.module';
import { AddEventComponent } from './add-event/add-event.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { EventInfoComponent } from './event-info/event-info.component';
import {CoreDirectivesModule} from "../../../@core/directives/directives";
import {QuillModule} from "ngx-quill";


@NgModule({
    declarations: [AddEventComponent, EventInfoComponent],
    exports: [
        AddEventComponent
    ],
    imports: [
        CommonModule,
        EventRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CoreDirectivesModule,
        QuillModule
    ]
})
export class EventModule { }
