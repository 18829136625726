import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {CoreSidebarService} from "../../../@core/components/core-sidebar/core-sidebar.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {VolunteerService} from "../../shared/services/volunteer.service";

@Component({
  selector: 'app-volunteer',
  templateUrl: './volunteer.component.html',
  styleUrls: ['./volunteer.component.scss']
})
export class VolunteerComponent implements OnInit {

  showSearch = false;
  id: any;
  data: any;
  submitted: any = false;
  submitting: any = false;
  searchForm: FormGroup;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  volunteers = [];

  onFileChanged: any;
  viewContentData: any;

  constructor(private router: Router,
              private _coreSidebarService: CoreSidebarService,
              public modalService: NgbModal,
              private fb: FormBuilder,
              private volunteerService : VolunteerService,
              ) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-event').toggleOpen();
  }



  add(): void {
    this.data = undefined;
    this.toggleSidebar();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'heading': ['']
    });
    this.get();
  }


  getData(valueSubmit: any): void {
    if (valueSubmit) {
      this.get({page: 1, total: null, perpage: 15, pages: null});
      this.toggleSidebar();
    }
  }

  search() {
    const params = {
      'heading': (this.searchForm.value && this.searchForm.value.heading) ? this.searchForm.value.heading : ''
    };
    this.get(params);
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
      heading: (this.searchForm && this.searchForm.value && this.searchForm.value.heading) ? this.searchForm.value.heading : ''
    };
    this.get(params);
  }



  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.volunteers = [];
    this.volunteerService.get(params).subscribe(data => {
      console.log(data['data']);
      if (data && data['data'].length > 0) {
        this.volunteers = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.volunteers = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }










}
