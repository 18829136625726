import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {VolunteerComponent} from "./volunteer.component";

const routes: Routes = [
  {
    path: 'volunteer',
    component: VolunteerComponent,
    data: {animation:'volunteer'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VolunteerRoutingModule { }
