<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button (click)="toggleSidebar()" aria-label="Close" class="close" data-dismiss="modal" type="button">
                    ×
                </button>
                <div class="modal-header">
                    <h5 *ngIf="!data || !data['id']" class="modal-title">Add Causes</h5>
                    <h5 *ngIf="data && data['id']" class="modal-title">Update Causes</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form [formGroup]="addBlogForm" class="form mt-2 shadow-none" novalidate>
                        <div class="container">
                            <div class="row pb-2">
                                <!-- <div class="col-12">
                                     <div class="form-group">
                                         <label for="blog_categories_id">Blog Categories</label>
                                         <select class="form-control" formControlName="blog_categories_id"
                                                 id="blog_categories_id"
                                                 type="text">
                                             <option value="">Select Blog Categories</option>
                                             <option *ngFor="let blogCategory of blogCategories"
                                                     [value]="blogCategory?.id">
                                                 {{blogCategory?.name}}
                                             </option>
                                         </select>
                                         <small
                                                 *ngIf="submitted && addBlogForm.get('blog_categories_id').hasError('required')"
                                                 class="text-danger">
                                             Field is required.
                                         </small>
                                     </div>
                                 </div>-->
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <input class="form-control" formControlName="title" id="title" type="text">
                                        <small *ngIf="submitted && addBlogForm.get('title').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="description">Description</label>
                                        <textarea class="form-control resize-none" formControlName="description"
                                                  id="description"
                                                  type="text"></textarea>
                                        <small
                                                *ngIf="submitted && addBlogForm.get('description').hasError('required')"
                                                class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="days">Days</label>
                                        <input class="form-control" formControlName="days" id="days" type="text">
                                        <small *ngIf="submitted && addBlogForm.get('days').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <!--  <div class="col-12">
                                      <div class="form-group mb-0">
                                          <label for="tags">Tags</label>
                                          <textarea (keyup.space)="addTags()" class="form-control"
                                                    cols="30" formControlName="tags" id="tags" rows="3"
                                                    style="resize: none"></textarea>
                                          <small *ngIf="submitted && addBlogForm.get('tags').hasError('required')"
                                                 class="text-danger">
                                              Field is required.
                                          </small>
                                      </div>
                                  </div>-->
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button (click)="toggleSidebar()" aria-label="Close" class="btn btn-danger"
                                            data-dismiss="modal"
                                            rippleEffect type="button">
                                        Cancel
                                    </button>
                                    <button (click)="submit()" *ngIf="!data || !data['id']"
                                            class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Submit
                                    </button>
                                    <button (click)="update()" *ngIf="data && data['id']"
                                            class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add new user Ends-->
</div>
