export const locale = {
    lang: 'en',
    data: {
        MENU: {
            DASHBOARD: 'Dashboard',
            BLOGS: 'Blogs',
            EVENTS: 'Events',
            CONTACTUS: 'Contact Us',
            VOLUNTEER: 'Volunteer',
            CAUSES: 'Causes',
        }
    }
};
