import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CausesRoutingModule } from './causes-routing.module';
import { AddCausesComponent } from './add-causes/add-causes.component';
import {CoreDirectivesModule} from "../../../@core/directives/directives";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


@NgModule({
    declarations: [AddCausesComponent],
    exports: [
        AddCausesComponent
    ],
    imports: [
        CommonModule,
        CausesRoutingModule,
        CoreDirectivesModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class CausesModule { }
