import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CoreSidebarService} from "../../../../@core/components/core-sidebar/core-sidebar.service";
import {AlertService} from "../../../shared/services/alert.service";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../../shared/services/global.service";
import {BlogService} from "../../../shared/services/blog.service";
import {EventService} from "../../../shared/services/event.service";
import {CausesService} from "../../../shared/services/causes.service";

@Component({
  selector: 'app-add-causes',
  templateUrl: './add-causes.component.html',
  styleUrls: ['./add-causes.component.scss']
})
export class AddCausesComponent implements OnInit {
  addBlogForm: FormGroup;
  id: any;
  submitted: any = false;
  submitting: any = false;
  onFileChanged: any;
  fileCourseCover: any;
  insights: any = [];
  allInsight: any = [];
  blogCategories: any = [];
  blogCategoryId: any;
  tags: string[] = [];
  authorList: any;
  @Output() valueSubmitted = new EventEmitter<any>();
  @Input() data: any;


  constructor(private _coreSidebarService: CoreSidebarService,
              private fb: FormBuilder,
              private alertService: AlertService,
              public http: HttpClient,
              public globalService: GlobalService,
              private blogService: BlogService,
              private causeService: CausesService
  ) {
  }
  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-event').toggleOpen();
  }

  refresh() {
    this.addBlogForm = this.fb.group({
      'title': ['', (!this.id) ? Validators.required : ''],
      'description': ['', (!this.id) ? Validators.required : ''],
      'days': ['', (!this.id) ? Validators.required : '']
    });
    if (this.data && this.data['id']) {
      this.patchForm();
    }
  }


  patchForm() {
    if (this.data) {
      this.getBlog(this.data['id']);
    }
    this.addBlogForm.patchValue({
      'title': (this.data && this.data['title']) ? this.data['title'] : '',
      'description': (this.data && this.data['description']) ? this.data['description'] : '',
      'days': (this.data && this.data['days']) ? this.data['days'] : ''
    });
  }



  getBlog(id) {
    this.insights = [];
    this.causeService.get(id).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.allInsight = (data && data['data']) ? data['data'] : [];
      } else {
        this.allInsight = [];
      }
    });
  }


  submit() {
    this.submitted = true;
    if (!this.addBlogForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'title': (this.addBlogForm.value && this.addBlogForm.value.title) ? this.addBlogForm.value.title : '',
        'description': (this.addBlogForm.value && this.addBlogForm.value.description) ? this.addBlogForm.value.description : '',
        'days': (this.addBlogForm.value && this.addBlogForm.value.days) ? this.addBlogForm.value.days : ''
      };
      this.submitting = true;
      this.causeService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.valueSubmitted.emit(true);
          this.addBlogForm.reset();
          this.addBlogForm.patchValue({
            'title': '',
            'description': '',
            'days': ''

          });
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
            this.alertService.showErrors(error['error']['errors'].title[0]);
            return;
          }
          if (error['error']['errors'].description && error['error']['errors'].description.length > 0) {
            this.alertService.showErrors(error['error']['errors'].description[0]);
            return;
          }
          if (error['error']['errors'].days && error['error']['errors'].days.length > 0) {
            this.alertService.showErrors(error['error']['errors'].days[0]);
            return;
          }
        }
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addBlogForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'title': (this.addBlogForm.value && this.addBlogForm.value.title) ? this.addBlogForm.value.title : '',
        'description': (this.addBlogForm.value && this.addBlogForm.value.description) ? this.addBlogForm.value.description : '',
        'days': (this.addBlogForm.value && this.addBlogForm.value.days) ? this.addBlogForm.value.days : ''
      };
      this.submitting = true;
      this.causeService.update(this.data['id'], params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.valueSubmitted.emit(true);
        this.addBlogForm.reset();
        this.addBlogForm.patchValue({
          'title': '',
          'description': '',
          'days': ''
        });
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
            this.alertService.showErrors(error['error']['errors'].title[0]);
            return;
          }
          if (error['error']['errors'].description && error['error']['errors'].description.length > 0) {
            this.alertService.showErrors(error['error']['errors'].description[0]);
            return;
          }
          if (error['error']['errors'].days && error['error']['errors'].days.length > 0) {
            this.alertService.showErrors(error['error']['errors'].days[0]);
            return;
          }
        }
      });
    }
  }

  onFileChange(e, type, index?) {
    this.onFileChanged = e;
    this.blogService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
      if (type === 'CAUSE_COVER') {
        this.fileCourseCover = data['data'];
      }
      this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    }, error => {
      this.alertService.showErrors('Something went wrong, please try again');
    });
  }

  openInsightCover() {
    window.open(this.fileCourseCover['url'], '_blank');
  }

}
