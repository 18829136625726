<div class="content-wrapper">
    <div class="content-body">
        <section id="articles">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold">Blog Content - {{viewContentData?.heading}}</h4>
                        <button (click)="modalService.dismissAll('')" aria-label="Close" class="close"
                                data-dismiss="modal"
                                type="button">
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row p-1">
                            <div [innerHTML]="viewContentData['content']"
                                 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Volunteers</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button (click)="showSearch = !showSearch"
                            class="btn btn-primary pull-right color-white color-white users-btn">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                </div>
            </div>
            <div *ngIf="showSearch" class="row search-card mt-3">
                <div class="col-12">
                    <form [formGroup]="searchForm" class="form mt-2 mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="heading" placeholder="heading"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button (click)="search()"
                                        [disabled]="submitting"
                                        class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn">
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!--            <pre class="text-white">{{blogs|json}}</pre>-->
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Phone No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Occupation</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Message</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Date & Time</h6>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody *ngIf="volunteers && volunteers.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let volunteer of volunteers; let i = index">

                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ?
                                                    pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{volunteer?.name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{volunteer?.email}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{volunteer?.contact}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{volunteer?.occupation}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{volunteer?.message}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{volunteer?.created_at}}</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="5">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page" [boundaryLinks]="true"
                                [collectionSize]="pagination.total" [maxSize]="5" [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
