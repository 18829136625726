import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CoreSidebarService} from "../../../../@core/components/core-sidebar/core-sidebar.service";
import {AlertService} from "../../../shared/services/alert.service";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../../shared/services/global.service";
import {BlogService} from "../../../shared/services/blog.service";
import {AuthorService} from "../../../shared/services/author.service";
import {EventService} from "../../../shared/services/event.service";

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit {

  addBlogForm: FormGroup;
  id: any;
  submitted: any = false;
  submitting: any = false;
  onFileChanged: any;
  fileCourseCover: any;
  insights: any = [];
  allInsight: any = [];
  blogCategories: any = [];
  blogCategoryId: any;
  tags: string[] = [];
  authorList: any;
  @Output() valueSubmitted = new EventEmitter<any>();
  @Input() data: any;

  constructor(private _coreSidebarService: CoreSidebarService,
              private fb: FormBuilder,
              private alertService: AlertService,
              public http: HttpClient,
              public globalService: GlobalService,
              private blogService: BlogService,
              private eventService: EventService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-event').toggleOpen();
  }

  refresh() {
    this.addBlogForm = this.fb.group({
      'heading': ['', (!this.id) ? Validators.required : ''],
      'short_description': ['', (!this.id) ? Validators.required : ''],
      'timing': ['', (!this.id) ? Validators.required : ''],
      'venue': ['', (!this.id) ? Validators.required : ''],
    });
    if (this.data && this.data['id']) {
      this.patchForm();
    }
  }


  patchForm() {
    if (this.data) {
      this.getBlog(this.data['id']);
    }
    this.addBlogForm.patchValue({
      'heading': (this.data && this.data['heading']) ? this.data['heading'] : '',
      'short_description': (this.data && this.data['short_description']) ? this.data['short_description'] : '',
      'timing': (this.data && this.data['timing']) ? this.data['timing'] : '',
      'venue': (this.data && this.data['venue']) ? this.data['venue'] : '',
    });
  }



  getBlog(id) {
    this.insights = [];
    this.eventService.get(id).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.allInsight = (data && data['data']) ? data['data'] : [];
      } else {
        this.allInsight = [];
      }
    });
  }


  submit() {
    this.submitted = true;
    if (!this.addBlogForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'heading': (this.addBlogForm.value && this.addBlogForm.value.heading) ? this.addBlogForm.value.heading : '',
        'short_description': (this.addBlogForm.value && this.addBlogForm.value.short_description) ? this.addBlogForm.value.short_description : '',
        'timing': (this.addBlogForm.value && this.addBlogForm.value.timing) ? this.addBlogForm.value.timing : '',
        'venue': (this.addBlogForm.value && this.addBlogForm.value.venue) ? this.addBlogForm.value.venue : '',
      };
      this.submitting = true;
      this.eventService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.valueSubmitted.emit(true);
          this.addBlogForm.reset();
          this.addBlogForm.patchValue({
            'heading': '',
            'short_description': '',
            'timing': '',
            'venue': '',

          });
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
            this.alertService.showErrors(error['error']['errors'].heading[0]);
            return;
          }
          if (error['error']['errors'].short_description && error['error']['errors'].short_description.length > 0) {
            this.alertService.showErrors(error['error']['errors'].short_description[0]);
            return;
          }
          if (error['error']['errors'].timing && error['error']['errors'].timing.length > 0) {
            this.alertService.showErrors(error['error']['errors'].timing[0]);
            return;
          }
          if (error['error']['errors'].venue && error['error']['errors'].venue.length > 0) {
            this.alertService.showErrors(error['error']['errors'].venue[0]);
            return;
          }
        }
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addBlogForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'heading': (this.addBlogForm.value && this.addBlogForm.value.heading) ? this.addBlogForm.value.heading : '',
        'short_description': (this.addBlogForm.value && this.addBlogForm.value.short_description) ? this.addBlogForm.value.short_description : '',
        'timing': (this.addBlogForm.value && this.addBlogForm.value.timing) ? this.addBlogForm.value.timing : '',
        'venue': (this.addBlogForm.value && this.addBlogForm.value.venue) ? this.addBlogForm.value.venue : '',
      };
      this.submitting = true;
      this.eventService.update(this.data['id'], params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.valueSubmitted.emit(true);
        this.addBlogForm.reset();
        this.addBlogForm.patchValue({
          'heading': '',
          'short_description': '',
          'timing': '',
          'venue': '',
        });
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
            this.alertService.showErrors(error['error']['errors'].heading[0]);
            return;
          }
          if (error['error']['errors'].short_description && error['error']['errors'].short_description.length > 0) {
            this.alertService.showErrors(error['error']['errors'].short_description[0]);
            return;
          }
          if (error['error']['errors'].timing && error['error']['errors'].timing.length > 0) {
            this.alertService.showErrors(error['error']['errors'].timing[0]);
            return;
          }
          if (error['error']['errors'].venue && error['error']['errors'].venue.length > 0) {
            this.alertService.showErrors(error['error']['errors'].venue[0]);
            return;
          }
        }
      });
    }
  }

  onFileChange(e, type, index?) {
    this.onFileChanged = e;
    this.blogService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
      if (type === 'BLOG_COVER') {
        this.fileCourseCover = data['data'];
      }
      this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    }, error => {
      this.alertService.showErrors('Something went wrong, please try again');
    });
  }

  openInsightCover() {
    window.open(this.fileCourseCover['url'], '_blank');
  }

}
