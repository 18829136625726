import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {CoreSidebarService} from "../../../@core/components/core-sidebar/core-sidebar.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BlogService} from "../../shared/services/blog.service";
import {AlertService} from "../../shared/services/alert.service";
import {EventService} from "../../shared/services/event.service";

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  showSearch = false;
  id: any;
  data: any;
  submitted: any = false;
  submitting: any = false;
  searchForm: FormGroup;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  blogs = [];
  events = [];

  onFileChanged: any;
  viewContentData: any;

  constructor(private router: Router,
              private _coreSidebarService: CoreSidebarService,
              public modalService: NgbModal,
              private fb: FormBuilder,
              private blogService: BlogService,
              private eventService: EventService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-event').toggleOpen();
  }

  edit(data) {
    this.data = data;
    this.toggleSidebar();
  }

  add(): void {
    this.data = undefined;
    this.toggleSidebar();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'heading': ['']
    });
    this.get();
  }


  getData(valueSubmit: any): void {
    if (valueSubmit) {
      this.get({page: 1, total: null, perpage: 15, pages: null});
      this.toggleSidebar();
    }
  }

  search() {
    const params = {
      'heading': (this.searchForm.value && this.searchForm.value.heading) ? this.searchForm.value.heading : ''
    };
    this.get(params);
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
      heading: (this.searchForm && this.searchForm.value && this.searchForm.value.heading) ? this.searchForm.value.heading : ''
    };
    this.get(params);
  }



  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.events = [];
    this.eventService.get(params).subscribe(data => {
      console.log(data['data']);
      if (data && data['data'].length > 0) {
        this.events = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.events = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  blogsInfoPage(data: any) {
    this.router.navigateByUrl('event-info/' + data.id);
  }
  delete(id) {
    this.eventService.delete(id).subscribe(data => {
      this.get();
    });
  }
  onFileChange(e, type, data) {
    this.onFileChanged = e;
    this.eventService.fileUploadAdmin({
      'type': type,
      'fileType': 'NORMAL',
      'insight_id': data.id
    }, this.onFileChanged).subscribe(data => {
      if (type === 'EVENT_BANNER' || type === 'EVENT_COVER') {
        if (data && data['data']) {
          this.get();
        }
      }
      this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    }, error => {
      this.alertService.showErrors('Something went wrong, please try again');
    });
  }

  openBlogBanner(data) {
    console.log(data);
    window.open(data['event_banner']['url'], '_blank');
  }
  openBlogCover(data) {
    window.open(data['event_cover']['url'], '_blank');
  }

  viewContent(content, data) {
    this.viewContentData = data;
    this.modalService.open(content);
  }
  updateStatus(data, status) {
    this.eventService.updateStatus(data.id, {'status': status}).subscribe(data => {
      this.submitting = false;
      this.submitted = false;
      this.get();
    }, error => {
      this.submitting = false;
      this.submitted = false;
    });
  }

}
