import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {UploadService} from "./upload.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
  providedIn: 'root'
})
export class CausesService extends BaseService{

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              private fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }



  get(data): Observable<any> {
    return this.getRequest(AppUrl.CAUSES(), data);
  }
  detail(id): Observable<any> {
    return this.getRequest(AppUrl.CAUSES(id));
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.CAUSES(), data);
  }

  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.CAUSES(id), data);
  }

  updateStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.CAUSES_STATUS(id), data);
  }
  delete(id): Observable<any> {
    return this.deleteRequest(AppUrl.CAUSES(id));
  }
  fileUpload(params, event): Observable<any> {
    return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
  }

  fileUploadAdmin(params, event): Observable<any> {
    return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD_ADMIN(), params, event);
  }
}
