import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CausesComponent} from "./causes.component";
import {AddCausesComponent} from "./add-causes/add-causes.component";

const routes: Routes = [
  {
    path: 'causes',
    component: CausesComponent,
    data: {animation:'causes'}
  },
  {
    path: 'causes/add',
    component: AddCausesComponent,
    data: {animation:'causes/add'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CausesRoutingModule { }
