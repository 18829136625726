import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'MENU.DASHBOARD',
        type: 'item',
        icon: 'fas fa-sign',
        url: 'dashboard',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },

    {
        id: 'blogs',
        title: 'Blogs',
        translate: 'MENU.BLOGS',
        type: 'item',
        icon: 'fas fa-blog',
        url: 'blogs',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'events',
        title: 'Events',
        translate: 'MENU.EVENTS',
        type: 'item',
        icon: 'fas fa-video',
        url: 'events',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'causes',
        title: 'Causes',
        translate: 'MENU.CAUSES',
        type: 'item',
        icon: 'fa fa-address-card-o',
        url: 'causes',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'contactus',
        title: 'Contact Us',
        translate: 'MENU.CONTACTUS',
        type: 'item',
        icon: 'fas fa-user',
        url: 'contactus',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'volunteers',
        title: 'Volunteers',
        translate: 'MENU.VOLUNTEER',
        type: 'item',
        icon: 'fas fa-users',
        url: 'volunteer',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },


];
